import React, { useState, useContext } from "react";
import styled from "styled-components";
import ModalProduto from "../ModalProduto";
import ProdutoItem from "./ProdutoItem";
import { LayoutContext } from "contexts/layout";

const ContainerContent = styled.div`

`;

function ListaProdutos({ items = [] }) {
  const { layoutData, update: updateContext } = useContext(LayoutContext);

  const [openModal, setOpenModal] = useState(false);
  const [produtoEdicao, setProdutoEdicao] = useState(null);

  return (
    <React.Fragment>
      <ModalProduto
        open={openModal}
        item={produtoEdicao}
        onToggle={() => setOpenModal(!openModal)}
        onSaved={() => {
          setOpenModal(false);
          updateContext();
        }}
      />

      <ContainerContent>
        {items.map((produto) => (
          <ProdutoItem
            onEdit={(produto) => {
              setOpenModal(true);
              setProdutoEdicao(produto);
            }}
            forceUpdate={updateContext}
            produto={produto}
            layoutData={layoutData}
          />
        ))}
      </ContainerContent>
    </React.Fragment>
  );
}

export default ListaProdutos;
