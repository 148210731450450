import React, { useContext, useCallback, useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Button, TextField } from "@material-ui/core";
import ReactPixel from "react-facebook-pixel";
import Swal from "sweetalert2";

import FormaPagamento from "./tabs/FormaPagamento";
import UserAddress, { ButtonOutlined } from "../../components/UserAddress";
import ListaProdutos from "../../components/CarrinhoCard/ListaProdutos";
import ProdutoTotais from "../../components/CarrinhoCard/ProdutoTotais";
import ModalPedidoOff from "../../components/ModalPedidoOff";
import { FooterNav } from "../../DeliveryPage/components/ListaCardapio";

import { LayoutContext } from "contexts/layout";
import useFinalizarPedido from "actions/pedido/useFinalizarPedido";
import useCookies from "infra/hooks/useCookies";
import AuthClient from "infra/auth/AuthClient";
import usePedidoOff from "infra/hooks/usePedidoOff";
import {
  getObservacao,
  setObservacao,
  getOrigem,
  getAllCart,
} from "services/cart";
import { navigate } from "infra/router/history";
import { formatMoney } from "infra/utils";

function Conteudo() {
  const { isLoading, produtos, finalizar } = useFinalizarPedido();
  const { layoutData, update: updateContext } = useContext(LayoutContext);
  const { getCookie, setCookie } = useCookies();

  const isUserLogged = AuthClient.isUserLogged();
  const origem = getOrigem();
  const userData = AuthClient.getData();

  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega"));
  const [openModalCadastro, setOpenModalCadastro] = useState(false);

  const isPedidoOff =
    !isUserLogged &&
    !!getCookie("idPedidoOff") &&
    !!getCookie("chavePedidoOff") &&
    layoutData?.wgPedidoDeliveryOFF;

  const { venda: dadosPedidoOff } = usePedidoOff(
    getCookie("idPedidoOff"),
    getCookie("chavePedidoOff")
  );

  const userNaoInformado = !isUserLogged && !isPedidoOff;

  useEffect(() => {
    if (!layoutData.flgPedidoPontoEntrega && pontoEntrega) {
      localStorage.removeItem("pontoEntrega");
    }
  }, [layoutData.flgPedidoPontoEntrega, pontoEntrega]);

  useEffect(() => {
    if (layoutData.codigoPixelFacebookCart) {
      ReactPixel.init(layoutData.codigoPixelFacebookCart);
      ReactPixel.pageView();
    }
  }, [layoutData.codigoPixelFacebookCart]);

  const handleInformarUsuario = useCallback(() => {
    if (!isUserLogged && !getCookie("idPedidoOff")) {
      return Swal.fire({
        title: "Aviso",
        text: "Você precisa fazer login antes de continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Fazer login",
        cancelButtonText: layoutData.wgPedidoDeliveryOFF
          ? "Continuar sem cadastro"
          : "Cancelar",
      }).then((result) => {
        if (result.value) {
          updateContext({ openLogin: true });
        } else if (layoutData.wgPedidoDeliveryOFF && result.isDismissed) {
          setOpenModalCadastro(true);
        }
      });
    }
  }, [isUserLogged, getCookie, layoutData.wgPedidoDeliveryOFF, updateContext]);

  const EmptyCartMessage = () => (
    <div
      style={{
        fontSize: "70px",
        color: "#CCCCCC",
        textAlign: "center",
        margin: "48px 0",
      }}
    >
      <div className="mdi mdi-cart-outline" />
      <span style={{ fontSize: "28px" }}>Nenhum produto no carrinho</span>
      <FooterNav
        cartItemCount={getAllCart().length}
        primaryColor={layoutData.primaryColor}
      />
    </div>
  );

  const ObservacaoField = () => (
    <div style={{ marginTop: 32 }}>
      <div
        style={{
          display: "flex",
          backgroundColor: "#ebebeb",
          padding: 8,
          marginBottom: 16,
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: 18 }}>Observação</span>
      </div>
      <TextField
        fullWidth
        variant="outlined"
        value={getObservacao()}
        onChange={({ target: { value } }) => setObservacao(value)}
        multiline
        rows={3}
        label="Alguma observação?"
        placeholder="Observação final do pedido"
      />
    </div>
  );

  if (!produtos.length) {
    return <EmptyCartMessage />;
  }

  return (
    <Container style={{ marginBottom: 32 }}>
      <Row>
        <Col md={9}>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: "16px 0" }}
            onClick={() => navigate("delivery")}
          >
            Adicionar mais produtos
          </Button>
          <SectionTitle title={'Produtos'} />
          <br />
          <ListaProdutos items={produtos} />
          <SectionTitle title={'Meus dados'} />
          {userNaoInformado ? (
            <>
              <div style={{ textAlign: "center", marginTop: 16 }}>
                <span style={{ fontWeight: "bold", fontSize: 14 }}>
                  Faça LOGIN ou informe seus dados para realizar o pedido
                </span>
                <ButtonOutlined
                  color={layoutData.primaryColor}
                  onClick={handleInformarUsuario}
                >
                  Informar dados
                </ButtonOutlined>
              </div>
            </>
          ) : (
            <>
              <div style={{
                fontWeight: "bold",
                fontSize: 14,
                marginBottom: 16,
                marginTop: 16,

              }}>
                <span
                >
                  Nome: {userData?.nome || dadosPedidoOff?.nome}
                </span>
                {
                  !!dadosPedidoOff?.nome && isPedidoOff &&
                  <ButtonOutlined
                    color={layoutData.primaryColor}
                    onClick={() => setOpenModalCadastro(true)}
                  >
                    Alterar
                  </ButtonOutlined>
                }
              </div>

              <SectionTitle title={'Endereço'} />
              <UserAddress horizontal forceUpdate={updateContext} />
              {
                !!dadosPedidoOff?.nome && isPedidoOff &&
                <ButtonOutlined
                  color={layoutData.primaryColor}
                  onClick={() => setOpenModalCadastro(true)}
                >
                  Alterar
                </ButtonOutlined>
              }
              {origem === "delivery" && (
                <div style={{ margin: "40px 0" }}>
                  Prazo médio de entrega: {layoutData.tempoMedioEntregaNome}
                </div>
              )}

              <SectionTitle title={'Total do pedido'} />
              <div style={{ display: "flex", marginTop: 16 }}>
                <div style={{ flex: 1, fontSize: 16 }}>Total em Produtos</div>
                <div
                  style={{
                    color: "#333",
                  }}
                >
                  R$ {formatMoney(produtos?.reduce((total, item) => total + item.valorTotal, 0))}
                </div>
              </div>
              <ProdutoTotais />

              <SectionTitle title={'Forma de pagamento'} />
              <FormaPagamento
                forceUpdate={updateContext}
                isPedidoOff={isPedidoOff}
              />
              <ObservacaoField />
              <Button
                onClick={finalizar}
                disabled={isLoading || userNaoInformado}
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: isLoading
                    ? "#ccc"
                    : layoutData.primaryColor,
                  color: "white",
                  marginTop: 32,
                }}
              >
                {isLoading ? "Finalizando..." : "Finalizar Pedido"}
              </Button>
            </>
          )}
        </Col>
      </Row>
      {
        openModalCadastro &&
        (<ModalPedidoOff
          open={openModalCadastro}
          onToggle={() => setOpenModalCadastro(!openModalCadastro)}
          onSaved={(values) => {
            setOpenModalCadastro(false);
            setCookie("idPedidoOff", values?.id, 7);
            setCookie("chavePedidoOff", values?.chave, 7);
            updateContext();
            window.location.reload();
          }}
        />)
      }
    </Container>
  );
}

export default Conteudo;


const SectionTitle = ({ title }) => {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#ebebeb",
        padding: 8,
      }}
    >
      <span style={{ fontWeight: "bold", fontSize: 18 }}>
        {title}
      </span>
    </div>
  )
}