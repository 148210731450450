import React from "react";
import { Route, Switch } from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import CardapioPage from "./pages/CardapioPage";
import MenuPage from "./pages/Menu/MenuPage";
import CarrinhoPage from "./pages/CarrinhoPage";
import AcompanhamentoPage from "./pages/AcompanhamentoPage";
import PagamentoPixPage from "./pages/PagamentoPixPage";
import HistoricoPedidosPage from "./pages/HistoricoPedidosPage";
import MinhasFormasPagamentoPage from "./pages/MinhasFormasPagamentoPage";
import HistoricoPedidosDetalhesPage from "./pages/HistoricoPedidosDetalhesPage";
import QRCodePage from "./pages/QRCodePage";
import EventosPrincipalPage from "./pages/Eventos/PrincipalPage";
import EventosCarrinhoPage from "./pages/Eventos/CarrinhoPage";
import EventosStatusPage from "./pages/Eventos/StatusPage";
import EventosReservasPage from "./pages/Eventos/ReservasPage";
import LayoutDefault from "./LayoutDefault";
import ContaMenuPage from "./pages/Menu/ContaPage";
import FidelidadePage from "./pages/Menu/FidelidadePage";
import EventoProvider from "./pages/Eventos/EventoContext";
import history from "infra/router/history";
import RedirectQRCode from "./pages/Menu/RedirectQRCode";
import DeliveryPontoEntrega from "./pages/DeliveryPontoEntrega";
import Reserva from "./pages/Reserva";
import MinhasReservas from "./pages/MinhasReservas";
import HistoricoPedidosMenu from "./pages/HistoricoPedidosMenuPage";
import HistoricoPedidosMenuDetalhes from "./pages/HistoricoPedidosMenuDetalhesPage";
import DeliveryPage from "./pages/DeliveryPage";
import CarrinhoDeliveryPage from "./pages/CarrinhoDeliveryPage";

function Router() {
  history.listen(() => {
    window.scroll(0, 0);
  });

  return (
    <LayoutDefault>
      <EventoProvider>
        <Switch>

          <Route exact path="/:site" component={IndexPage} />
          <Route exact path="/:site/menu" component={MenuPage} />
          <Route exact path="/:site/menu/:guiaId" component={MenuPage} />
          <Route exact path="/:site/eventos" component={EventosPrincipalPage} />

          <Route
            exact
            path="/:site/eventos/:eventoId/status"
            component={EventosStatusPage}
          />
          <Route
            exact
            path="/:site/eventos/reservas"
            component={EventosReservasPage}
          />
          <Route
            exact
            path="/:site/eventos/carrinho"
            component={EventosCarrinhoPage}
          />
          <Route exact path="/:site/fidelidade" component={FidelidadePage} />
          <Route
            exact
            path="/:site/menu/conta/:saidaId"
            component={ContaMenuPage}
          />
          <Route
            exact
            path="/:site/menu/conta/:saidaId/chave-cns/:chaveCns"
            component={RedirectQRCode}
          />
          <Route exact path="/:site/cardapio" component={CardapioPage} />
          <Route exact path="/:site/delivery" component={DeliveryPage} />
          <Route exact path="/:site/carrinho" component={CarrinhoPage} />
          <Route exact path="/:site/carrinho-delivery" component={CarrinhoDeliveryPage} />

          <Route
            exact
            path="/:site/pagamento-pix/:id"
            component={PagamentoPixPage}
          />
          <Route
            exact
            path="/:site/acompanhamento/:id"
            component={AcompanhamentoPage}
          />
          <Route
            exact
            path="/:site/historico-pedidos"
            component={HistoricoPedidosPage}
          />
          <Route
            exact
            path="/:site/historico-pedidos/:id"
            component={HistoricoPedidosDetalhesPage}
          />
          <Route
            exact
            path="/:site/historico-pedidos-menu"
            component={HistoricoPedidosMenu}
          />
          <Route
            exact
            path="/:site/historico-pedidos-menu/:id"
            component={HistoricoPedidosMenuDetalhes}
          />
          <Route
            exact
            path="/:site/minhas-formas-pagamento"
            component={MinhasFormasPagamentoPage}
          />
          <Route exact path="/:site/qrcode" component={QRCodePage} />
          <Route
            exact
            path="/:site/delivery-ponto-entrega"
            component={DeliveryPontoEntrega}
          />
          <Route
            exact
            path="/:site/reserva"
            component={Reserva}
          />
          <Route
            exact
            path="/:site/minhas-reservas"
            component={MinhasReservas}
          />

        </Switch>

      </EventoProvider>
    </LayoutDefault>
  );
}

export default Router;
