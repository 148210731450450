import { useState, useContext, useCallback } from "react";
import Swal from "sweetalert2";
import { LayoutContext } from "contexts/layout";
import AuthClient from "infra/auth/AuthClient";
import { navigate } from "infra/router/history";
import {
  getAllCart,
  getChaveComanda,
  getFormaPagamento,
  getFormaPagamentoOnline,
  getNome,
  getOrigem,
  getRetirarNoLocal,
  getSaida,
  getValorPagamento,
} from "services/cart";
import {
  checkout,
  checkoutMenu,
  checkoutMenuAcompanhamentoConta,
  checkoutMenuAcompanhamentoContaDeslogado,
  checkoutPedidoDireto,
} from "services/pedido";
import { showSuccess } from "infra/alerta";
import useCookies from "infra/hooks/useCookies";
import usePedidoOff from "infra/hooks/usePedidoOff";

export default function useFinalizarPedido() {
  const { layoutData, update: updateContext } = useContext(LayoutContext);
  const { getCookie } = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useState({
    empresa: layoutData,
  });
  const authData = AuthClient.getData();
  const isUserLogged = AuthClient.isUserLogged();
  const endereco = authData
    ? authData.endereco
    : layoutData?.dadosPessoaPedidoOff?.endereco;

  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega"));
  const formaPagamento = getFormaPagamento();
  const formaPagamentoOnline = getFormaPagamentoOnline();
  const valorPagamento = getValorPagamento();
  const retirarNoLocal = getRetirarNoLocal();
  const produtos = getAllCart();
  const origem = getOrigem();
  const saidaId = getSaida();
  const isPedidoOff = !isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  const isPedidoSimples = layoutData.flgPedidoMenuSimples && origem !== "delivery" && !saidaId;
  const isPedidoComTaxaDeServico = layoutData?.flgPedidoMenuComissionado && origem === "menu" && !retirarNoLocal;
  const isPontoEntregaConfig = origem === 'delivery' && layoutData.flgPedidoPontoEntrega && layoutData.flgPedidoPontoEntregaConfig === 'F';
  const isPedidoComandaDeslogado = origem === 'menu' && !isUserLogged && layoutData.opcoesMenu.pedidoComandaDeslogado

  const { venda: dadosPedidoOff } = usePedidoOff(
    getCookie("idPedidoOff"),
    getCookie("chavePedidoOff")
  );
  const enderecoPedidoOff = {
    logradouro: dadosPedidoOff?.logradouro,
    nomeBairro: dadosPedidoOff?.bairroNome,
    nomeCidade: dadosPedidoOff?.cidadeNome,
    nomeEstado: dadosPedidoOff?.ufSigla,
    endNumero: dadosPedidoOff?.numero,
    ...dadosPedidoOff,
  }
  const finalizar = useCallback(async () => {
    const nome = getNome() || authData?.nome;
    const chaveComanda =
      getChaveComanda() || layoutData?.contaAtualMenuDto?.chaveMobile;

    if (origem !== "delivery" && !saidaId && !isPedidoSimples) {
      if (!nome) {
        return Swal.fire({
          title: "Aviso",
          text: "Informe o nome corretamente!",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }

      if (!chaveComanda) {
        return Swal.fire({
          title: "Aviso",
          text: "Informe a chave da comanda corretamente!",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    }

    if (!produtos || !produtos.length) {
      return Swal.fire({
        title: "Aviso",
        text: "Você precisa selecionar pelo menos um produto!",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    if (isPontoEntregaConfig && !pontoEntrega) {
      return Swal.fire({
        title: "Aviso",
        text: "Este restaurante somente atende pontos de entrega, selecione um ponto de entrega para realizar o pedido!",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    if (!retirarNoLocal && origem === "delivery" && !isPedidoOff && !pontoEntrega) {
      if (!endereco?.id) {
        return Swal.fire({
          title: "Aviso",
          text: "Você precisa informar um endereço de entrega!",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }

      if (!endereco.flgAceitaEntrega && !pontoEntrega && !endereco?.chave) {
        return Swal.fire({
          title: "Aviso",
          text: "Esta empresa não aceita entregar para o endereço selecionado!",
          icon: "warning",
          confirmButtonText: "Alterar endereço",
          cancelButtonText: "Voltar",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            updateContext({ openModalEndereco: true });
          }
        });
      }
    }

    if (origem === "delivery") {
      if (!layoutData.flgAceitaPgtoDelivery) {
        if (!formaPagamento?.id) {
          return Swal.fire({
            title: "Aviso",
            text: "Insira uma forma de pagamento!",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
      } else {
        if (!formaPagamento?.id && !formaPagamentoOnline) {
          return Swal.fire({
            title: "Aviso",
            text: "Insira uma forma de pagamento!",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
      }

      if (formaPagamento?.nome?.toLowerCase().includes("dinheiro")) {
        if (!valorPagamento) {
          return Swal.fire({
            title: "Aviso",
            text: "Informe o valor do pagamento em dinheiro!",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
      }
    }

    setIsLoading(true);

    try {
      let response = null;

      if (isPedidoSimples) {
        // Se o pedido é simples, chama checkoutPedidoDireto
        response = await checkoutPedidoDireto(form, isPedidoComTaxaDeServico);
      } else if (origem === "delivery") {
        // Se a origem é delivery, verifica se tem ponto de entrega
        // e chama checkout com os parâmetros corretos
        if (isPedidoOff) {
          // Se tem idPedidoOff, chama checkout com parâmetros específicos
          response = await checkout(
            form,
            retirarNoLocal,
            isPedidoOff,
            getCookie("idPedidoOff"),
            getCookie("chavePedidoOff"),
            null,
            enderecoPedidoOff
          );
        } else {
          // Se não tem idPedidoOff, chama checkout com ponto de entrega
          response = await checkout(
            form,
            retirarNoLocal,
            false,
            null,
            null,
            pontoEntrega ? pontoEntrega.id : null,
            null
          );
        }
      } else if (saidaId) {
        // Se tem saidaId, chama check  MenuAcompanhamentoConta
        if (isPedidoComandaDeslogado) {
          response = await checkoutMenuAcompanhamentoContaDeslogado(
            form,
            nome,
            chaveComanda
          );
        } else {
          response = await checkoutMenuAcompanhamentoConta(
            form,
            nome,
            chaveComanda
          );
        }
      } else {
        // Caso contrário, chama checkoutMenu
        response = await checkoutMenu(form);
      }

      const { error, data } = response;
      if (error) {
        return Swal.fire({
          title: "Aviso",
          text: error,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }

      if (origem === "delivery") {
        let message =
          "Pedido realizado com sucesso! Aguarde a aprovação do restaurante";
        // pagamento

        if (data?.pix?.qrCode) {
          message =
            "Pedido realizado com sucesso! Aguarde a aprovação do pagamento PIX";
        }

        await showSuccess(message);
        if (data?.pix?.qrCode) {
          navigate(`pagamento-pix/${data.id}`);
        } else {
          navigate(`acompanhamento/${data.id}`);
        }

      } else {
        if (isPedidoComandaDeslogado) {
          return Swal.fire(
            "Aviso",
            "Seu pedidofoi enviado com sucesso! Em breve o garçom irá entrega-lo",
            "success"
          ).then(() => {
            navigate(`menu`, true);
          });
        } else {
          return Swal.fire(
            "Aviso",
            "Seu pedido foi enviado. Aguarde o processamento",
            "success"
          ).then(() => {
            if (data?.saidaId) {
              navigate(`menu/conta/${data.saidaId}`, true);
            } else {
              navigate(`historico-pedidos-menu/${data?.id}`, true);
            }
          });
        }

      }
    } catch (e) {
      console.log('erro', e)
      return Swal.fire({
        title: "Aviso",
        text: "Houve um problema ao realizar o pedido, entre em contato com o restaurante.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } finally {
      setIsLoading(false);
    }
  }, [authData, layoutData, origem, saidaId, isPedidoSimples, produtos, isPontoEntregaConfig, pontoEntrega, retirarNoLocal, isPedidoOff, endereco, updateContext, formaPagamento, formaPagamentoOnline, valorPagamento, form, isPedidoComTaxaDeServico, getCookie, enderecoPedidoOff, isPedidoComandaDeslogado]);

  return {
    finalizar,
    isLoading,
    form,
    produtos,
  };
}
