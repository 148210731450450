import React from "react";
import Appbar from "../../components/Appbar";
import styled from "styled-components";
import ScrollTop from "../../components/ScrollTop";
import Footer from "../../components/Footer";
import HeaderEmpresa from "../components/HeaderEmpresa";
import ListaCardapio from "./components/ListaCardapio";

const Body = styled.div`
  padding-top: 90px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 30px;
    }
  }
`;

function DeliveryPage() {
  return (
    <Body>
      <Appbar />

      <HeaderEmpresa showCarrinho={false} />

      <ListaCardapio />

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default DeliveryPage;
