import React, { useState, useContext, useEffect } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import styled from "styled-components";

import AuthClient from "infra/auth/AuthClient";
import { LayoutContext } from "contexts/layout";
import { getLocalizacaoMesaId, getRetirarNoLocal, setRetirarNoLocal } from "services/cart";

import ModalEnderecos from "./ModalEnderecos";
import useCookies from "infra/hooks/useCookies";
import usePedidoOff from "infra/hooks/usePedidoOff";

import ModalPontoEntrega from "./ModalPontoEntrega";
import ModalMesas from "./ModalMesas";
import { useMediaQuery } from "infra/utils";

export const ButtonOutlined = styled.button`
  background: none;
  padding: 12px 8px;
  border: 1px solid ${(props) => props.color};
  outline: none;
  color: ${(props) => props.color};
  display: block;
  width: 90%;
  float: ${(props) => (props.horizontal ? "left" : "none")};
  border-radius: 6px;
  font-size: 13px;
  margin-top: 8px;
  margin-left: 8px;
  &:focus {
    outline: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'column')}; /* Itens em coluna se isMobile for true */
  margin-left: ${(props) => (props.horizontal && !props.isMobile ? 100 : 0)}px; /* Ajusta margem esquerda baseado em horizontal e isMobile */
  margin-top: 8px;

  @media all and (max-width: 768px) {
    & {
      margin-left: 0;
    }
  }
`;

function UserAddress({ forceUpdate, horizontal = false, isPedidoSimples = false }) {
  const [openModalEndereco, setOpenModalEndereco] = useState(false);
  const [openModalPontoEntrega, setOpenModalPontoEntrega] = useState(false);
  const { layoutData } = useContext(LayoutContext);
  const authData = AuthClient.getData();
  const userIsLogged = AuthClient.isUserLogged();
  const [openModalMesas, setOpenModalMesas] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { getCookie } = useCookies();

  const { venda: dadosPedidoOff } = usePedidoOff(
    getCookie("idPedidoOff"),
    getCookie("chavePedidoOff")
  );
  const isPedidoOff = !userIsLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  const isPedidoDiretoSomenteBalcao = layoutData.opcoesMenu.pedidoDiretoSomenteBalcao && isPedidoSimples

  const retirarNoLocal = getRetirarNoLocal();


  useEffect(() => {
    if (isPedidoDiretoSomenteBalcao) {
      setRetirarNoLocal(true);
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPedidoDiretoSomenteBalcao])


  if ((!authData || !Object.keys(authData).length) && !isPedidoOff) {
    return layoutData.flgAceitaRetirarPedido ? (
      <RetirarLocal forceUpdate={forceUpdate} retirarNoLocal={retirarNoLocal} />
    ) : null;
  }

  const enderecoPedidoOff = {
    logradouro: dadosPedidoOff?.logradouro,
    nomeBairro: dadosPedidoOff?.bairroNome,
    nomeCidade: dadosPedidoOff?.cidadeNome,
    nomeEstado: dadosPedidoOff?.ufSigla,
    endNumero: dadosPedidoOff?.numero,
  }
  const mesa = getLocalizacaoMesaId();
  const enderecoAuth = authData?.endereco;

  if (!enderecoAuth && !enderecoPedidoOff) {
    return (
      <div>
        <ButtonOutlined
          color={layoutData.primaryColor}
          onClick={() => setOpenModalEndereco(true)}
        >
          Informar Endereço
        </ButtonOutlined>

        {layoutData.flgAceitaRetirarPedido ? (
          <RetirarLocal
            forceUpdate={forceUpdate}
            retirarNoLocal={retirarNoLocal}
          />
        ) : null}

        <ModalEnderecos
          open={openModalEndereco}
          forceUpdate={forceUpdate}
          layoutData={layoutData}
          onToggle={() => {
            setOpenModalEndereco(!openModalEndereco);
          }}
        />
      </div>
    );
  }

  if (isPedidoSimples) {
    return (
      <div>
        {mesa && !retirarNoLocal && (
          <h5 style={{ marginTop: '16px' }}>Receber em: {mesa.nome}</h5>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={retirarNoLocal}
              onChange={() => {
                if (!isPedidoDiretoSomenteBalcao) {
                  setRetirarNoLocal(!retirarNoLocal);
                  forceUpdate();
                }

              }}
              name="retirarNoBalcao"
            />
          }
          label="Retirar no balcão"
        />
        {!isPedidoDiretoSomenteBalcao && (
          <ButtonOutlined
            color={layoutData.primaryColor}
            onClick={() => setOpenModalMesas(true)}
          >
            Receber na mesa
          </ButtonOutlined>
        )}

        <ModalMesas
          updateContext={forceUpdate}
          open={openModalMesas}
          onToggle={() => setOpenModalMesas(!openModalMesas)}
        />
      </div>
    );
  }
  const enderecoEmpresa = {
    logradouro: layoutData.endLogradouro,
    nomeBairro: layoutData.endBairroNome,
    nomeCidade: layoutData.endCidadeNome,
    nomeEstado: layoutData.endUFNome,
    endNumero: layoutData.endNumero,
  };

  const isPontoEntrega = !!localStorage.getItem("pontoEntrega");
  let enderecoConsiderar;

  if (isPontoEntrega) {
    // Se existe um ponto de entrega definido, usa este endereço
    enderecoConsiderar = JSON.parse(localStorage.getItem("pontoEntrega"));
  } else if (retirarNoLocal) {
    // Se não, verifica se a opção de retirar no local está ativa
    enderecoConsiderar = enderecoEmpresa;
  } else {
    // Caso contrário, usa o endereço de autenticação ou, se não disponível, o endereço do pedido off
    enderecoConsiderar = enderecoAuth ?? enderecoPedidoOff;
  }

  const isPontoEntregaConfig = layoutData.flgPedidoPontoEntrega && layoutData.flgPedidoPontoEntregaConfig === 'F';

  return (
    <div>
      <ModalEnderecos
        open={openModalEndereco}
        forceUpdate={forceUpdate}
        layoutData={layoutData}
        onToggle={() => {
          setOpenModalEndereco(!openModalEndereco);
        }}
      />
      {openModalPontoEntrega && (
        <ModalPontoEntrega
          open={openModalPontoEntrega}
          forceUpdate={forceUpdate}
          layoutData={layoutData}
          onToggle={() => {
            setOpenModalPontoEntrega(!openModalPontoEntrega);
          }}
        />
      )}

      {!retirarNoLocal && !isPontoEntrega ? (
        <h5 style={{ marginTop: 16 }}>Entregar em</h5>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h5>{!isPontoEntrega ? "Retirar em" : "Retirar no ponto de entrega"} </h5>
        </div>
      )}

      <div style={{ display: horizontal && !isMobile ? "flex" : "block" }}>
        <div>
          <div style={{ display: "flex", marginTop: 8 }}>
            <div style={{ paddingRight: 16 }}>
              {retirarNoLocal ? (
                <StoreIcon style={{ color: layoutData.primaryColor }} />
              ) : null}
              {!retirarNoLocal ? (
                <i
                  className="fa fa-map-marker fa-2x"
                  style={{ color: layoutData.primaryColor }}
                />
              ) : null}
            </div>
            <div style={{ flex: 1 }}>
              {isPontoEntrega && (
                <p style={{ fontWeight: "bold", fontSize: 13, margin: 0 }}>
                  {enderecoConsiderar.nome ?? '--'}
                </p>
              )}
              {enderecoConsiderar.logradouro ||
                enderecoConsiderar.endNumero ||
                enderecoConsiderar.numero ||
                enderecoConsiderar.bairroNome ||
                enderecoConsiderar.nomeBairro ||
                enderecoConsiderar.cidadeNome ||
                enderecoConsiderar.nomeCidade ||
                enderecoConsiderar.nomeEstado ? (
                <p style={{ color: "#888", fontSize: 12 }}>
                  {isPontoEntrega
                    ? enderecoConsiderar.logradouroFull ?? '--'
                    : (enderecoConsiderar.logradouro ?? '--') + ", " + (enderecoConsiderar.endNumero ?? enderecoConsiderar.numero ?? '--')}
                  <p style={{ margin: 0 }}>
                    {isPontoEntrega
                      ? enderecoConsiderar.bairroNome ?? '--'
                      : enderecoConsiderar.nomeBairro ?? '--'}{" "}
                    ,{" "}
                    {isPontoEntrega
                      ? enderecoConsiderar.cidadeNome ?? '--'
                      : enderecoConsiderar.nomeCidade ?? '--'}{" "}
                    {isPontoEntrega ? "" : "- " + (enderecoConsiderar.nomeEstado ?? '--')}
                  </p>
                </p>
              ) : (
                <p style={{ color: "#888", fontSize: 12 }}>Sem endereço informado</p>
              )}
            </div>


          </div>

          <div>
            {layoutData.flgAceitaRetirarPedido && !isPontoEntrega && !isPedidoSimples && !isPontoEntregaConfig ? (
              <RetirarLocal
                forceUpdate={forceUpdate}
                retirarNoLocal={retirarNoLocal}
              />
            ) : null}
          </div>
        </div>

        {!retirarNoLocal && !isPedidoOff && (
          <ButtonContainer isMobile={isMobile}>
            <ButtonOutlined
              color={layoutData.primaryColor}
              onClick={() => {
                isPontoEntregaConfig || isPontoEntrega
                  ? setOpenModalPontoEntrega(true)
                  : setOpenModalEndereco(true);
              }}
            >
              {isPontoEntregaConfig ? 'Escolher Ponto de Entrega' : 'Escolher Endereço '}
            </ButtonOutlined>
            {layoutData.flgPedidoPontoEntrega && !isPontoEntregaConfig && (
              isPontoEntrega ? (
                <ButtonOutlined
                  color={layoutData.primaryColor}
                  onClick={() => {
                    localStorage.removeItem("pontoEntrega"); // Limpa o ponto de entrega
                    forceUpdate(); // Atualiza a página
                    setOpenModalEndereco(true); // Abre a modal para escolher o endereço
                  }}
                >
                  Mudar para delivery
                </ButtonOutlined>
              ) : (
                <ButtonOutlined
                  color={layoutData.primaryColor}
                  onClick={() => setOpenModalPontoEntrega(true)} // Abre a modal para escolher o ponto de entrega
                >
                  Trocar para ponto de entrega
                </ButtonOutlined>
              )
            )}
          </ButtonContainer>
        )}
      </div>
    </div>
  );
}

function RetirarLocal({ retirarNoLocal, forceUpdate }) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={retirarNoLocal}
            onChange={() => {
              setRetirarNoLocal(!retirarNoLocal);
              forceUpdate();
            }}
            name="retirarNoLocal"
          />
        }
        label="Deseja retirar no local ?"
      />
    </div>
  );
}

export default UserAddress;
