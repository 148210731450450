import React, { useState, useEffect, useCallback, useContext } from "react";
import { Spinner, Row, Col } from "reactstrap";
import { Button, Tabs, Tab } from "@material-ui/core";
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import { getModalidadePagamento } from "services/cardapio";
import {
  setFormaPagamentoOnline,
  setFormaPagamento,
  getFormaPagamento,
  getFormaPagamentoOnline,
  getValorPagamento,
  setValorPagamento,
  getOrigem,
} from "services/cart";
import { LayoutContext } from "contexts/layout";
import ModalTroco from "./ModalTroco";
import ListaMinhasFormasPagamento from "../../../MinhasFormasPagamentoPage/components/ListaMinhasFormasPagamento";

const FormaPagamentoItem = styled.div`
  border: 1px solid #ddd;
  padding: 8px 16px;
  margin: 6px 0;
  border-radius: 6px;
  cursor: pointer;
  background: white;
  border-color: ${(props) => (props.active ? props.primaryColor : "#ddd")};

  &:hover {
    background: #efefef;
  }
`;

function FormaPagamento({ forceUpdate, carrinhoMenu, isPedidoSimples = false, isPedidoOff }) {
  const { layoutData } = useContext(LayoutContext);
  const [stepTab, setStepTab] = useState(
    isPedidoSimples && layoutData.flgPedidoMenuSimplesPosPago ? 0 :
      isPedidoSimples ? 1 :
        carrinhoMenu ? 1 :
          0
  );
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [openModalTroco, setOpenModalTroco] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formaPagamentoOnline = getFormaPagamentoOnline() || {};
  const formaPagamento = getFormaPagamento() || {};
  const valorPagamento = getValorPagamento();
  const origem = getOrigem();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const data = await getModalidadePagamento();
        setFormasPagamento(data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleToggle = useCallback(
    (value) => {
      const pagamentoSelecionado = formasPagamento.find(
        (x) => x.id === Number(value)
      );

      if (
        pagamentoSelecionado &&
        pagamentoSelecionado.nome.toLowerCase().includes("dinheiro")
      ) {
        setOpenModalTroco(true);
      }

      if (pagamentoSelecionado) {
        setFormaPagamento(pagamentoSelecionado);
      }

      setFormaPagamentoOnline(null);
      forceUpdate();
    },
    [formasPagamento, forceUpdate]
  );

  const handleFormaPagamentoOnline = (value) => {
    setFormaPagamentoOnline(value);
    setFormaPagamento(null);
    forceUpdate();
  };

  return (
    <div>
      {isLoading && <Spinner />}

      <ModalTroco
        open={openModalTroco}
        valorPagamento={valorPagamento}
        setValorPagamento={setValorPagamento}
        forceUpdate={forceUpdate}
        onOk={() => {
          setOpenModalTroco(false);
        }}
        onClose={() => {
          setValorPagamento(0);
          setOpenModalTroco(false);
        }}
      />

      <Tabs
        value={stepTab}
        onChange={(ev, v) => setStepTab(v)}
        indicatorColor="primary"
        textColor="primary"
      >
        {(isPedidoSimples && layoutData.flgPedidoMenuSimplesPosPago) || !isPedidoSimples ? (
          <Tab
            label={`Pagamento na ${origem === "delivery" ? "entrega" : "mesa"}`}
          />
        ) : null}
        {layoutData.flgAceitaPgtoDelivery && !isPedidoOff ? (
          <Tab label={`Pagamento online`} />
        ) : null}
      </Tabs>

      <br />

      {stepTab === 0 && ((isPedidoSimples && layoutData.flgPedidoMenuSimplesPosPago) || !isPedidoSimples) ? (
        <Row>
          {formasPagamento.map((item, index) => (
            <Col sm={6} key={index}>
              <FormaPagamentoItem
                primaryColor={layoutData.primaryColor}
                active={Number(formaPagamento.id) === Number(item.id)}
                onClick={() => handleToggle(item.id)}
              >
                {Number(formaPagamento.id) === Number(item.id) ? (
                  <CheckIcon
                    style={{ color: layoutData.primaryColor, marginRight: 8 }}
                  />
                ) : null}
                <strong>{item.nome}</strong>

                {Number(formaPagamento.id) === Number(item.id) &&
                  item.nome.toLowerCase().includes("dinheiro") ? (
                  <div
                    className="btn-add-pagamento"
                    style={{ float: "right", marginTop: -4 }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      style={{ float: "right" }}
                      onClick={() => setOpenModalTroco(true)}
                    >
                      Informar valor
                    </Button>
                  </div>
                ) : null}
              </FormaPagamentoItem>
            </Col>
          ))}
        </Row>
      ) : null}

      {layoutData.flgAceitaPgtoDelivery && (stepTab === 1 || (isPedidoSimples && !layoutData.flgPedidoMenuSimplesPosPago)) ? (
        <div>
          <ListaMinhasFormasPagamento
            origemCarrinho
            selected={formaPagamentoOnline}
            onSelect={(item) => handleFormaPagamentoOnline(item)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default FormaPagamento;
